import ShareRouteUc from "@/share_route/use_cases/shareRouteUc";
import ShareRouteRepository from "@/share_route/models/shareRouteRepository";
import ShareRoute from "@/share_route/models/shareRoute";
import notifications from "@/shared/notifications";

const repository = new ShareRouteRepository(this)
const uc = new ShareRouteUc(repository)

export default {
    namespaced: true,

    state: {
        loading: true,
        timer: null,
        shareRoute: new ShareRoute()
    },

    mutations: {
        setLoading(state, value) {
            state.loading = value
        },
        setTimer(state, value) {
            state.timer = value
        }
    },

    actions: {
        async captureRoute({state}, {serviceId, scope}) {
            try {
                state.timer = setTimeout(function () {
                    state.loading = false
                    notifications.notify('No existe ruta');
                }, 20000);
                const repository = new ShareRouteRepository(scope)
                await repository.receivedRoute(serviceId)

            } catch (error) {
                throw new Error(error);
            }
        },


        async subscribeFCMToken() {
            try {
                await uc.subscribeToken()
            } catch (error) {
                throw new Error(error)
            }
        },


        async requestShareFirestore({state}, {city, serviceId}) {
            try {
                await uc.requestShareFirestore(city, serviceId)
            } catch (error) {
                throw new Error(error)
            }
        },

        clearTimer({state}) {
            if (state.timer) {
                clearTimeout(state.timer)
                state.timer = null
            }
        },

        updateRoute({state}, data) {
            state.shareRoute.fromJsonResponse(data)
        },

        deleteRoute({state}) {
            state.shareRoute.setIsSharingRoute('false')
        },

        async subscribeRouteTopic({state}, {city, serviceId, scope}) {
            try {
                state.timer = setTimeout(function () {
                    state.loading = false
                    notifications.notify('No existe ruta');
                }, 20000);

                const repository = new ShareRouteRepository(scope)
                repository.subscribeRouteTopic(city, serviceId)
            } catch (error) {
                console.error(error)
            }
        },
    }
}
