<template>
  <b-modal
      id="blockedNotifications"
      content-class="modal"
      hide-header
      hide-footer
      centered
  >
    <div>
      <div class="modal__container-title">
        <p class="modal__title-block">Bloqueaste las notificaciones</p>
      </div>

      <div>
        <p>Para el correcto funcionamiento de la ruta <span class="modal__subtitle">Necesitamos que las actives</span></p>

      </div>
      <div class="modal__container-information">
        <div class="modal__content">
          <img src="@/assets/images/lock.png">
          <p class="modal__supplementary-text">1.  Haz clic en el candado de la esquina superior izquierda.</p>
        </div>
        <div class="modal__content">
          <img src="@/assets/images/tune.png">
          <p class="modal__supplementary-text">2. Después, haz clic en “Permisos”</p>
        </div>
        <div class="modal__content">
          <img src="@/assets/images/sound.png">
          <p class="modal__supplementary-text">3. Por último, haz clic en el icono de la campana y activa la opción “Mostrar notificaciones”.</p>
        </div>
      </div>
    </div>
  </b-modal>

</template>

<script>
export default {
  name: "BlockedNotifications"
}
</script>

