/*
 * Bus de eventos para la comunicación entre componentes
 */

const EB = {}

EB.install = Vue => {
  Vue.prototype.$bus = new Vue()
}

export default EB
