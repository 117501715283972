<template>
  <b-modal
      id="enableNotifications"
      hide-header
      content-class="modal"
      hide-footer
      centered
  >
    <div class="modal__container-sound">
      <img class="modal__img-sound" src="@/assets/images/notifications.png">
    </div>
    <div class="modal__container-text">
      <p>Para asegurarnos de que recibas todas las novedades de la ruta</p>
      <p class="modal__text-information">Necesitamos tu permiso para enviarte notificaciones</p>
    </div>
    <div>
      <button class="button-notifications" @click="requestNotificationPermission" >Permitir notificaciones</button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "EnableNotifications",
  methods: {
    async requestNotificationPermission() {
      if ('Notification' in window) {
        Notification.requestPermission().then(() => {
          window.location.reload()
        })
      } else {
        console.log('Las notificaciones no son compatibles con este navegador.');
      }
    },
  }
}
</script>


