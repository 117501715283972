export default class ShareRouteUc {
  #repository = null;

  constructor(repository) {
    this.#repository = repository;
  }

  async subscribeToken() {
    try {
      await this.#repository.sendToken();
    } catch (error) {
      throw new Error(error);
    }
  }

  async requestShareFirestore(city, serviceID) {
    try {
      this.#repository.requestShareFirestore(city, serviceID);
    } catch (error) {
      throw new Error(error);
    }
  }
}
