<template>
  <div class="l-container">
    <div class="spinner-container">
      <div v-if="loading" class="spinner">
        <div class="loader"></div>
      </div>
      <map-route/>
      <information-card/>
      <enable-notifications />
      <blocked-notifications />
    </div>
  </div>
</template>

<style>
.spinner-container {
  position: relative;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Evita que el spinner capture eventos de clic */
}

.modal {
  position: fixed;
  z-index: 99999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}
</style>

<script>
import MapRoute from "@/share_route/context/components/MapRoute.vue"
import {mapActions, mapMutations, mapState} from "vuex"
import 'firebase/messaging'
import InformationCard from "@/share_route/context/components/InformationCard.vue";
import EnableNotifications from "@/share_route/context/components/EnableNotifications.vue";
import BlockedNotifications from "@/share_route/context/components/BlockedNotifications.vue";

export default {
  name: "ShareRoutes",
  components: {BlockedNotifications, EnableNotifications, InformationCard, MapRoute},
  data() {
    return {
      serviceId: '',
      city: '',
    }
  },
  computed: {
    ...mapState('fcmStore', ['timer', 'shareRoute', 'loading'])
  },
  methods: {
    ...mapMutations('fcmStore', ['setTimer', "setLoading"]),
    ...mapActions('fcmStore', ['subscribeFCMToken', 'captureRoute', 'subscribeRouteTopic', 'requestShareFirestore', 'clearTimer', 'updateRoute', 'deleteRoute']),
    async validateNotificationPermission() {
      if ('Notification' in window) {
        if (Notification.permission !== "granted") {
          if (Notification.permission === "denied")  this.$bvModal.show('blockedNotifications')
          else  this.$bvModal.show('enableNotifications')

          this.setLoading(false)
        } else {
          await this.subscribeFCMToken()
          await this.captureRoute({serviceId: this.serviceId, scope: this})
        }
      } else {
        console.log('Las notificaciones no son compatibles con este navegador.');
      }
    },
    async requestNotificationPermission() {
      if ('Notification' in window) {
        Notification.requestPermission().then(() => {
          window.location.reload()
        })
      } else {
        console.log('Las notificaciones no son compatibles con este navegador.');
      }
    },
  },
  async mounted() {
    const value = window.location.search
    const urlParams = new URLSearchParams(value)
    this.serviceId = urlParams.get('serviceId')
    this.city = urlParams.get('cityId')

    await this.requestShareFirestore({city: this.city, serviceId: this.serviceId})
    await this.subscribeRouteTopic({city: this.city, serviceId: this.serviceId, scope: this})

    this.$bus.$on('clearTimer', this.clearTimer)
    this.$bus.$on('updateRoute', this.updateRoute)
    this.$bus.$on('deleteRoute', this.deleteRoute)
  }
}
</script>