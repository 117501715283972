import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from "vue-sweetalert2";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import EventBus from '@/shared/eventBus'

/*************** Import Styles ******************/
import '@/assets/scss/main.scss'
import 'sweetalert2/dist/sweetalert2.min.css';


Vue.config.productionTip = false
Vue.use(EventBus)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
